/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store } from "@ngrx/store";
import { RootState, appActions, fromApp } from "./store";
import { CONVERCUS_IFRAME_MESSAGE_IDENTIFIER, SHOPIFY_HOST } from "./constants";
import { WalletParams } from "./interfaces";
import { environment } from "../environments/environment";
import { ShopifyAppBridgeService } from "./services/shopify-app-bridge/shopify-app-bridge.service";
import { Router } from "@angular/router";
import { Program } from "./services/api";
import { combineLatest, first, tap } from "rxjs";

export const initializeApp =
  (store: Store<RootState>, shopifyAppBridgeService: ShopifyAppBridgeService, router: Router): Function =>
    async () => {
    // Convercus
      const messageHandler = (event: any): void => {
        try {
          if (event.data.identifier === CONVERCUS_IFRAME_MESSAGE_IDENTIFIER) {
            const { accessToken, programId, programName, integrationName, activeSideBar }: WalletParams = JSON.parse(event?.data?.params);

            combineLatest([
              store.select(fromApp.selectAccessToken),
              store.select(fromApp.selectIframeProgramId),
              store.select(fromApp.selectIframeProgramName),
              store.select(fromApp.selectIframeIntegrationName),
              store.select(fromApp.selectSideNavStats),
            ]).pipe(
              first(),
              tap(([
                currentAccessToken,
                currentIframeProgramId,
                currentIframeProgramName,
                currentIframeIntegrationName,
                currentSideNavActive,
              ]) => {
                const isSameInfo = currentAccessToken === accessToken &&
                  currentIframeProgramId === programId &&
                  currentIframeProgramName === programName &&
                  currentIframeIntegrationName === integrationName &&
                  currentSideNavActive.active === activeSideBar;

                if (isSameInfo) {
                  window.removeEventListener("message", messageHandler);
                } else {
                  store.dispatch(appActions.setAccessToken({ accessToken }));
                  store.dispatch(appActions.setSideNav({ active: activeSideBar, hasLogOut: false }));
                  store.dispatch(appActions.setIframeProgramId({ iframeProgramId: programId }));
                  store.dispatch(appActions.setIframeProgramName({ iframeProgramName: programName }));
                  store.dispatch(appActions.setIframeIntegrationName({
                    iframeIntegrationName: integrationName as Program.IntegrationEnum,
                  }));

                  window.removeEventListener("message", messageHandler);
                  window.location.reload();
                }
              }),
            ).subscribe();
          }
        } catch (error) {
          window.removeEventListener("message", messageHandler);
        }
      };

      window.addEventListener("message", messageHandler);

      // Shopify
      try {
        const redirectUrl = `https://${environment.host}`;

        const urlParams = new URLSearchParams(window.location.search);

        const shop = urlParams.get("shop");
        const idToken = urlParams.get("id_token");

        if (shop) {
          if (idToken) {
            const host = urlParams.get("host");
            const programSlug = shop.replace(SHOPIFY_HOST, "");

            await shopifyAppBridgeService.initializeAppBridge({ apiKey: environment.shopifyClientId, host }, programSlug, shop);

            const URLFragments = window.location.pathname?.split("/").filter(Boolean) || [];
            router.navigate([programSlug, ...URLFragments]);
          } else {
            window.location.href = `https://${shop}/admin/oauth/authorize?client_id=${environment.shopifyClientId}&scope=&redirect_uri=${redirectUrl}`;
          }
        }
      } catch (error) {}
    };
