import { Languages } from "../enums";
import { MenuItem } from "../interfaces";
import { Program } from "../services/api";

export const DEFAULT_LANGUAGE = Languages.en;

export const AVAILABLE_LANGUAGES = [Languages.en, Languages.de, Languages.es];

export const PAGINATION_SIZE = 10;

export const PAGINATION_OPTIONS = [10, 25, 50];

export const DATE_INPUT_FORMAT = "yyyy-MM-dd";

export const SHOPIFY_HOST = ".myshopify.com";

export const SHOPIFY_MULTI_PASS_DOCS = "https://shopify.dev/docs/api/multipass";

export const CONVERCUS_DOCS_URL = "https://www.convercus.com/";

export const SUPPORT_DOCS_URL = "https://www.jericommerce.com/support";
export const API_DOCS_URL = "https://www.jericommerce.com/integrations/api";

export const CAMPAIGN_COUPONS_DOCS = "https://jericommerce.com/guides/use-coupon-on-campaigns";
export const ANONYMOUS_COUPONS_DOCS = "https://jericommerce.com/guides/create-an-anonymous-coupon";
export const EMAILS_CUSTOMIZATION_DOCS = " https://jericommerce.com/guides/emails-customization";
export const LOYALTY_LION_DOCS = "https://jericommerce.com/guides/configuration-lolyaltylion-integration";
export const MULTI_PASS_DOCS = "https://jericommerce.com/guides/shopify-multipass-customers-login-automatically";
export const ENABLE_PUSHES_DOCS = "https://jericommerce.com/guides/im-not-receiving-notifications-on-my-wallet-what-can-i-do";
export const PUBLIC_CAMPAIGNS_DOCS = "https://jericommerce.com/guides/how-to-distribute-an-anonymous-campaign";

export const URL_PATTERN = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

export const BACKEND_VARIABLES: MenuItem[] = [
  { title: "externalCustomerId" },
  { title: "email" },
  { title: "name" },
  { title: "phone" },
  { title: "loyaltyCustomerId" },
  { title: "balance" },
  { title: "balanceName" },
  { title: "memberSince" },
  { title: "tierStatus" },
  { title: "linkCustomerAppRewards" },
  { title: "linkCustomerAppProfile" },
];

export const MULTI_PASS = "multiPass";
export const MULTI_PASS_DEFAULT = "default";
export const MULTI_PASS_CUSTOM = "custom";

export const FRONTEND_VARIABLES: MenuItem[] = [
  {
    title: MULTI_PASS,
    integration: Program.IntegrationEnum.Shopify,
    children: [{ title: MULTI_PASS_DEFAULT }, { title: MULTI_PASS_CUSTOM }],
  },
];

export const COUPON_VARIABLE: MenuItem = { title: "coupon" };

export const BASE_FEATURES = ["events", "loyalty", "segments", "users"];

export const PASSES_PER_USAGE_CHARGE = 1_000;
export const SUBSCRIPTION_AMOUNT = 45.0;
export const USAGE_CHARGE_AMOUNT = 25.0;

export const CONVERCUS_CONTACT_EMAIL = "sales@convercus.com";

export const CONVERCUS_IFRAME_MESSAGE_IDENTIFIER = "convercus";
